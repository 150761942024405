@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'),
    url(/assets/fonts/Roboto-Regular.woff2) format('woff2'),
    url(/assets/fonts/Roboto-Regular.woff) format('woff'),
    url(/assets/fonts/Roboto-Regular.ttf) format('truetype'),
    url(/assets/fonts/Roboto-Regular.eot) format('eot');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url(/assets/fonts/Roboto-Italic.woff2) format('woff2'),
    url(/assets/fonts/Roboto-Italic.woff) format('woff'),
    url(/assets/fonts/Roboto-Italic.ttf) format('truetype'),
    url(/assets/fonts/Roboto-Italic.eot) format('eot');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url(/assets/fonts/Roboto-Bold.woff2) format('woff2'),
    url(/assets/fonts/Roboto-Bold.woff) format('woff'),
    url(/assets/fonts/Roboto-Bold.ttf) format('truetype'),
    url(/assets/fonts/Roboto-Bold.eot) format('eot');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto Mono'), local('RobotoMono-Regular'),
    url(/assets/fonts/RobotoMono-Regular.woff2) format('woff2'),
    url(/assets/fonts/RobotoMono-Regular.woff) format('woff'),
    url(/assets/fonts/RobotoMono-Regular.ttf) format('truetype'),
    url(/assets/fonts/RobotoMono-Regular.eot) format('eot');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
